import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

import { CreditCardPayload } from 'state/account/account.services';

export interface PaymentsState {
    isBusy: boolean;
    selectedPaymentMethod?: CreditCardPayload;
}

export const initialState: PaymentsState = {
    isBusy: false
};

const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        setSelectedPaymentMethod: (state, { payload }: PayloadAction<CreditCardPayload | undefined>) =>
            produce(state, (draftState) => {
                draftState.selectedPaymentMethod = payload;
            })
    }
});

export const paymentsActions = paymentsSlice.actions;

export default paymentsSlice.reducer;
