import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';

import CircleInfo from 'ui-kit/icons/info/circle-info-icon';

import 'components/alert-banner/alert-banner.style.scss';

import storageHelper from 'util/storageHelper';

import './cookie-banner.style.scss';

const hasBeenDismissed = () => storageHelper.cookies.getCookieAccepted();

const CookieBanner = (): ReactElement => {
    const { t } = useTranslation();
    
    const [dismissed, setDismissed] = useState(hasBeenDismissed());

    useEffect(() => {
        setInterval(() => {
            const isDismissed = hasBeenDismissed();
            if (isDismissed !== dismissed) {
                setDismissed(isDismissed);
            }
        }, 100);

    }, [dismissed]);

    const handleClose = () => {
        storageHelper.cookies.setCookieAccepted();
    };

    return (
        <>
            {!dismissed && (
                <Alert
                    className="alert-banner cookie-banner mb-0"
                    onClose={handleClose}
                    variant={'dark'}
                    dismissible
                    data-ga-location="Cookie Banner"
                    data-ga-type={'dark'}
                >
                    <Container>
                        <Row>
                            <Col
                                xs={{ span: 8, offset: 2 }}
                                sm={{ span: 8, offset: 2 }}
                                md={{ span: 12, offset: 0 }}
                                lg="12"
                            >
                                <p className="alert-message">
                                    <CircleInfo />
                                    {t('components.cookie.byUsingBirdi')}{' '}
                                    <Alert.Link href="/website-terms-of-use">
                                        {t('components.cookie.termsOfUse')}
                                    </Alert.Link>{' '}
                                    {t('components.cookie.and')}{' '}
                                    <Alert.Link href="/website-privacy-policy">
                                        {t('components.cookie.webPrivacy')}
                                    </Alert.Link>{' '}
                                    {t('components.cookie.includingCollection')}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Alert>
            )}
        </>
    );
};

export default CookieBanner;
