import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// UI Kit
import Button from 'ui-kit/button/button';
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { HealthConditionPills } from 'components/health-conditions/health-conditions.component';
import {
    AllergiesModalContent,
    HealthConditionsModalContent
} from 'components/health-profile/health-profile.component';
import { HealthProfileBubbleUpdateEvent } from 'components/health-profile/health-profile.props';
import ColumnSectionEditModeToggle, {
    ColumnSectionEditModeToggleRef
} from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

// Modal
import { HealthConditionsWarningModalContent } from 'pages/secure/profile/health-profile';

// States
import { accountFetchHealthConditionsRoutine } from 'state/account/account.routines';
import { accountProfileSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    medicalConditionsAllergiesDetailsRoutine,
    medicalConditionsDetailsRoutine
} from 'state/medical-conditions/medical-conditions.routines';
import { isLoadingHealthConditionsSelector } from 'state/medical-conditions/medical-conditions.selector';

import { getPhoneNumber } from 'util/globalVariables';

import { useHealthConditions } from 'hooks/useHealthConditions';

import './health-profile.style.scss';

interface HealthConditions {
    allergyChoices: (string | undefined)[];
    existingAllergies: string[];
    existingFreeformAllergies: string;
    conditionChoices: (string | undefined)[];
    existingConditions: string[];
    existingFreeformConditions: string;
    userHasNotSubmittedAllergies: boolean;
    userHasNotSubmittedConditions: boolean;
    ePostPatientNum: string;
}

export interface HealthProfileProps {
    className?: string;
    healthConditions: HealthConditions;
    includeAccordionSectionTitlePillIcon?: boolean;
    onUpdateHealthConditions: (e: HealthProfileBubbleUpdateEvent) => void;
}

const HealthProfile: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [hasFetchedConditions, setHasFetchedConditions] = useState<boolean>(false);
    const [hasUpdatedConditions, setHasUpdatedConditions] = useState<boolean>(false);
    const columnSectionRef = useRef<ColumnSectionEditModeToggleRef>(null);
    const profileObject = useSelector(accountProfileSelector);
    const isLoadingHealthConditions = useSelector(isLoadingHealthConditionsSelector);
    const healthConditions = useHealthConditions(profileObject?.hasMembership);
    const { existingAllergies, existingConditions, userHasNotSubmittedConditions, userHasNotSubmittedAllergies } =
        healthConditions;
    const isToggleOpen =
        hasFetchedConditions &&
        (!isLoadingHealthConditions || hasUpdatedConditions) &&
        (userHasNotSubmittedConditions || userHasNotSubmittedAllergies);

    const handleUpdateConditionsClick = ({ action, update }: HealthProfileBubbleUpdateEvent) => {
        dispatch(
            openModal({
                showClose: false,
                type: 'primary',
                size: 'lg',
                className: 'modal-health-profile-warning',
                headerContent: <BirdiModalHeaderDanger headerText={t('modals.healthConditions.title')} />,
                bodyContent: <HealthConditionsWarningModalContent translation={t} />,
                ctas: [
                    {
                        label: t('modals.healthConditions.submit'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                            dispatch(action(update));
                            setHasUpdatedConditions(true);
                            if (
                                columnSectionRef.current &&
                                !userHasNotSubmittedConditions &&
                                !userHasNotSubmittedAllergies
                            ) {
                                columnSectionRef.current.handleSaveChangesButtonClick();
                            }
                        },
                        dataGALocation: 'HealthProfileWarning'
                    }
                ]
            })
        );
    };

    const handleToggleHealthConditionsClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <HealthConditionsModalContent
                        title={t('modals.prescription.addHealthCondition.title')}
                        subTitle={t('modals.prescription.addAllergy.subTitle', {
                            phoneNumber: getPhoneNumber({})
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        submitLabel={t('modals.prescription.addHealthCondition.submit')}
                        isMembershipHealthConditions={profileObject?.hasMembership}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleToggleAllergiesClick = () => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: (
                    <AllergiesModalContent
                        title={t('modals.prescription.addAllergy.title')}
                        subTitle={t('modals.prescription.addAllergy.subTitle', {
                            phoneNumber: getPhoneNumber({})
                        })}
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        freeformConditionsLabel={t('components.healthConditions.labels.freeformAllergiesLabel')}
                        submitLabel={t('modals.prescription.addAllergy.submit')}
                        isMembershipHealthConditions={profileObject?.hasMembership}
                    />
                ),
                ctas: []
            })
        );
    };

    // Fetch health conditions
    useEffect(() => {
        if (profileObject) {
            if (profileObject?.hasMembership) {
                dispatch(
                    medicalConditionsDetailsRoutine.trigger({
                        epostPatientNumFamily: profileObject?.epostPatientNum
                    })
                );
                dispatch(
                    medicalConditionsAllergiesDetailsRoutine.trigger({
                        epostPatientNumFamily: profileObject?.epostPatientNum
                    })
                );
            } else {
                dispatch(accountFetchHealthConditionsRoutine.trigger());
            }
            setHasFetchedConditions(true);
        }
    }, [dispatch, profileObject]);

    const healthProfileContent = (
        <div className="health-profile">
            {userHasNotSubmittedConditions || userHasNotSubmittedAllergies ? (
                <div className="health-profile__empty">
                    <CircleInfo color={'#1a497f'} variant={'primary'} />
                    {t('components.medicineCabinetCart.healthProfile.emptyHealthConditions')}
                </div>
            ) : (
                <div
                    className="health-profile__intro"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(
                            t('components.medicineCabinetCart.healthProfile.intro') + getPhoneNumber({ isEnd: true })
                        )
                    }}
                />
            )}
            <div className="health-profile__conditions">
                <h5 className="health-profile__conditions-title">
                    {t('components.medicineCabinetCart.healthProfile.healthConditions')}
                </h5>
                {!userHasNotSubmittedConditions && (
                    <div className="health-profile__conditions-details">
                        <HealthConditionPills conditions={existingConditions} />
                    </div>
                )}
                <div className="health-profile__conditions-actions">
                    <Button
                        plusIcon
                        IconType="secondary"
                        className="column-section__button btn-bold"
                        variant="text-blue-light"
                        label={t('components.medicineCabinetCart.healthProfile.addHealthConditions')}
                        type="button"
                        onClick={handleToggleHealthConditionsClick}
                    />
                </div>
            </div>
            <div className="health-profile__conditions">
                <h5 className="health-profile__conditions-title">
                    {t('components.medicineCabinetCart.healthProfile.allergies')}
                </h5>
                {!userHasNotSubmittedAllergies && (
                    <div className="health-profile__conditions-details">
                        <HealthConditionPills conditions={existingAllergies} />
                    </div>
                )}
                <div className="health-profile__conditions-actions">
                    <Button
                        plusIcon
                        IconType="secondary"
                        className="column-section__button btn-bold"
                        variant="text-blue-light"
                        label={t('components.medicineCabinetCart.healthProfile.addAllergies')}
                        type="button"
                        onClick={handleToggleAllergiesClick}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <ColumnSectionEditModeToggle
            ref={columnSectionRef}
            bodyClassName="health-profile__content"
            className="health-profile"
            editModeContent={healthProfileContent}
            headerClassName="health-profile__header"
            title={t('components.medicineCabinetCart.healthProfile.title')}
            chevronButton={true}
            isToggleOpen={isToggleOpen}
        />
    );
};
export default HealthProfile;
