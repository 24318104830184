import classNames from 'classnames';
import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import Button from 'ui-kit/button/button';

import ColumnSection, { ColumnSectionProps } from 'components/sidebar-column/column-section/column-section.component';

import { noop } from 'util/function';

import './column-section-toggle.style.scss';

export interface ColumnSectionEditModeToggleProps extends Omit<ColumnSectionProps, 'headerActionContent'> {
    editModeContent?: React.ReactNode;
    onEditModeChange?: (isEditMode: boolean) => void;
    isToggleDisabled?: boolean;
    chevronButton?: boolean;
    isToggleOpen?: boolean;
}

export interface ColumnSectionEditModeToggleRef {
    handleSaveChangesButtonClick: () => void;
}

const ColumnSectionEditModeToggle = forwardRef(
    (props: ColumnSectionEditModeToggleProps, ref: Ref<ColumnSectionEditModeToggleRef>) => {
        const {
            children,
            editModeContent = null,
            onEditModeChange = noop,
            isToggleDisabled = false,
            chevronButton = false,
            isToggleOpen = false,
            className,
            ...remainingColumnSectionProps
        } = props;
        const [isEditMode, setIsEditMode] = useState<boolean>(false);
        const hasChevronClass = chevronButton ? 'has-chevron' : '';

        const updateEditMode = useCallback(
            (isEditing: boolean) => {
                setIsEditMode(isEditing);
                onEditModeChange(isEditing);
            },
            [onEditModeChange]
        );

        const handleChangeButtonClick = useCallback(() => {
            updateEditMode(true);
        }, [updateEditMode]);

        const handleSaveChangesButtonClick = useCallback(() => {
            updateEditMode(false);
        }, [updateEditMode]);

        useEffect(() => {
            if (isToggleDisabled || !isToggleOpen) {
                updateEditMode(false);
            }
            if (!isToggleDisabled && isToggleOpen) {
                updateEditMode(true);
            }
        }, [updateEditMode, isToggleDisabled, isToggleOpen]);

        useImperativeHandle(ref, () => ({ handleSaveChangesButtonClick }));

        return (
            <ColumnSection
                className={classNames('column-section-toggle', className, hasChevronClass)}
                headerAction={
                    isEditMode ? (
                        <Button
                            className="column-section__button btn-demi no-min-width"
                            variant="text-blue-light"
                            label={chevronButton ? '' : 'Save Changes'}
                            type="button"
                            chevron={chevronButton ? 'up' : undefined}
                            onClick={handleSaveChangesButtonClick}
                        />
                    ) : (
                        !isToggleDisabled && (
                            <Button
                                className="column-section__button btn-demi no-min-width"
                                variant="text-blue-light"
                                label={chevronButton ? '' : 'Change'}
                                type="button"
                                chevron={chevronButton ? 'down' : undefined}
                                onClick={handleChangeButtonClick}
                            />
                        )
                    )
                }
                {...remainingColumnSectionProps}
            >
                {isEditMode ? editModeContent : children}
            </ColumnSection>
        );
    }
);

export default ColumnSectionEditModeToggle;
