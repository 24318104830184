import { ProfileObjectPayload } from 'state/account/account.services';
import { Dependent } from 'state/family-profile/family-profile.reducers';

import { lowercaseAndCapitalize } from './cart';

const defineCaregiverName = (profileObject: ProfileObjectPayload, willUseEposPatienNum: boolean) => ({
    key: `${profileObject?.patientFirstName} ${profileObject?.patientLastName}`,
    label: lowercaseAndCapitalize(`${profileObject?.patientFirstName} ${profileObject?.patientLastName}`),
    value: willUseEposPatienNum
        ? profileObject?.epostPatientNum
        : [profileObject?.patientFirstName, profileObject?.patientLastName, profileObject?.epostPatientNum],
    planAlias: profileObject?.planAlias
});

const mapFamilyMembers = (familyMembers: Dependent[], willUseEposPatienNum: boolean) => {
    return familyMembers
        .filter(
            (familyMember) =>
                (familyMember.accountStatus === 'fullAccess' && familyMember.dateRevokeAccess === null) ||
                (familyMember.accountStatus === 'partialAccess' && familyMember.dateRevokeAccess === null)
        )
        .map(({ familyMemberName, ePostPatientNum, planAlias }) => ({
            key: familyMemberName,
            label: lowercaseAndCapitalize(familyMemberName),
            value: willUseEposPatienNum
                ? ePostPatientNum
                : [...lowercaseAndCapitalize(familyMemberName).split(' '), ePostPatientNum],
            planAlias
        }));
};

export const mapCaregiverAndFamilyMembers = (
    profileObject: ProfileObjectPayload,
    familyMembers: Dependent[],
    willUseEposPatienNum: boolean
) => {
    const caregiver = defineCaregiverName(profileObject, willUseEposPatienNum);
    const dependents = familyMembers.length > 0 ? mapFamilyMembers(familyMembers, willUseEposPatienNum) : [];

    return [caregiver, ...dependents];
};

export const defineNameFromEpostPatientNum = (
    profileObject: ProfileObjectPayload,
    familyMembers: Dependent[],
    ePostPatientNum: string
) => {
    const person = familyMembers.filter((member) => member.ePostPatientNum === ePostPatientNum);

    return person.length > 0
        ? lowercaseAndCapitalize(person[0].familyMemberName)
        : lowercaseAndCapitalize(`${profileObject?.patientFirstName} ${profileObject?.patientLastName}`);
};
