import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// Components & UI
import ToastBox from 'ui-kit/toast-box/toast-box';

import BirdiModalHeader from 'components/birdi-modal/birdi-modal-header';
// Modals
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import DrugFormularyBirdiSelect from 'components/drug-formulary-birdi-select/drug-formulary-birdi-select';
import DrugFormularyTable from 'components/drug-formulary-table/drug-formulary-table.component';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { paragraphToComponent } from 'providers/paragraphs/paragraphs';

// Styles
import './discount-drug-modal.style.scss';

interface DiscountDrugModalProps {
    accountHasInsurance: boolean;
    location?: 'TransferRx' | 'Membership';
    isModalOpen?: boolean;
    isMembershipUser?: boolean;
    // This prop is gonna be necesary in the future to display or not the blurb inside the selectBirdi modal.
    isOnDemandPlan?: boolean;
}

const DiscountDrugModal = ({
    accountHasInsurance,
    location = 'TransferRx',
    isModalOpen,
    isMembershipUser,
    isOnDemandPlan
}: DiscountDrugModalProps): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // DRX-2599: To ensure reusability of current components and data, we are utilizing the same drugs for cash price
    // returned by this query. This list may change in the future, so we recommend updating it based on the type of
    // modal being displayed.
    const pageData = useStaticQuery(graphql`
        query {
            birdiPriceDrugPage: allNodeLandingPage(
                filter: { path: { alias: { eq: "/drugs-available-birdi-price" } } }
            ) {
                nodes {
                    title
                    id
                    path {
                        langcode
                        alias
                    }
                    relationships {
                        field_landing_page_content {
                            ...supportedParagraphs
                        }
                    }
                }
            }
            formulary: allFormularyDrug(filter: { acCode: { eq: "BRD01" } }) {
                nodes {
                    drugName: onSaleDrugName
                    onSaleDrugName
                    brandDrugName
                    brandNameCode
                    genericProductCode
                    dosageForms {
                        dosageForm
                        strengths {
                            fullStrength
                            gpi
                            strength
                            strengthUnit
                        }
                    }
                }
            }
        }
    `);

    useEffect(() => {
        if (isModalOpen) {
            handleLinkClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalOpen]);

    const handleLinkClick = () => {
        let modalHeader;
        let modalBody;

        if ((isMembershipUser && location === 'TransferRx') || location === 'Membership') {
            modalHeader = (
                <BirdiModalHeader
                    title={<h1 className="birdi-select-header">{t('pages.drugsList.membership')}</h1>}
                    note={
                        isOnDemandPlan ? (
                            <div className="birdi-select-note">
                                <ToastBox variant="default" icon="default">
                                    <p className="m-0">{t('pages.drugsList.membershipNote')}</p>
                                </ToastBox>
                            </div>
                        ) : undefined
                    }
                    // eslint-disable-next-line react/no-children-prop
                    children={undefined}
                />
            );

            modalBody = <DrugFormularyBirdiSelect />;
        } else {
            modalHeader = <></>;

            if (pageData.birdiPriceDrugPage.nodes.length === 1) {
                modalBody = pageData.birdiPriceDrugPage.nodes[0]?.relationships?.field_landing_page_content.map(
                    (paragraph: any, index: number) => (
                        <React.Fragment key={`discountdrugs_${index}`}>
                            {paragraphToComponent(paragraph?.internal.type, paragraph, index + 10)}
                        </React.Fragment>
                    )
                );
            } else {
                modalBody = (
                    <>
                        <h1 className="birdi-select-header mb-5">
                            {t('pages.drugsList.title', {
                                formularyTitle: t('pages.drugsList.BRD01')
                            })}
                        </h1>

                        <DrugFormularyTable formulary={pageData.formulary?.nodes} />
                    </>
                );
            }
        }

        dispatch(
            openModal({
                size: 'xl',
                showClose: true,
                className: 'scroll-modal',
                headerContent: modalHeader,
                bodyContent: <BirdiModalContent icon={'none'} body={modalBody} />,
                ctas: [
                    {
                        label: t('modals.default.submit'),
                        variant: 'primary',
                        className: 'btn-bold',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'DiscountDrug'
                    }
                ]
            })
        );
    };

    return (
        // Modal displaying based on the conditions:
        <>
            {/* Show the cash price modal if the account doesn't have insurance and is in the TransferRx flow */}
            {!accountHasInsurance && location === 'TransferRx' && (
                <div className="mt-3">
                    <span
                        role="button"
                        tabIndex={0}
                        className="discount-link"
                        onClick={() => handleLinkClick()}
                        onKeyPress={() => handleLinkClick()}
                    >
                        {t('prescriptionInfoForm.messages.discountLink')}
                    </span>
                </div>
            )}

            {/* If the user doesn't have membership, is in the TransferRx flow, and is an insured patient, nothing should be displayed. */}
            {location === 'TransferRx' && accountHasInsurance && <></>}

            {/* If the user is on the Membership dashboard page and has membership, no component should be displayed, only the modal should be opened. */}
            {location === 'Membership' && isMembershipUser && <></>}
        </>
    );
};
export default DiscountDrugModal;
