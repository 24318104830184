import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

import { formatLocationAddress } from './discount-card.helpers';

export const discountCardSelector = (state: RootState) => state.discountCardReducers;

export const discountCardIsLoadingSelector = createSelector(
    discountCardSelector,
    (discountCard) => discountCard.isLoading
);

export const discountCardFilterIsLoadingSelector = createSelector(
    discountCardSelector,
    (discountCard) => discountCard.isFilterLoading
);

export const discountCardUserLocationSelector = createSelector(
    discountCardSelector,
    (discountCard) => discountCard.currentLocation
);

export const discountCardLocationRadiusSelector = createSelector(
    discountCardSelector,
    (discountCard) => discountCard.locationFilters?.radiusRange
);

export const discountCardUserLocationAddressSelector = createSelector(discountCardSelector, (discountCard) => {
    const location = discountCard.currentLocation?.address;

    if (!location) return undefined;

    return formatLocationAddress(location, false);
});

export const discountCardErrorSelector = createSelector(discountCardSelector, (discountCard) => discountCard.error);

export const discountCardScriptSaveAutocompleteValuesSelector = createSelector(discountCardSelector, (discountCard) => {
    return discountCard.autocompleteValues;
});

export const discountCardScriptSaveIsAutocompleteLoadingSelector = createSelector(
    discountCardSelector,
    (discountCard) => {
        return discountCard.isAutocompleteLoading;
    }
);

export const discountCardSearchResultsSelector = createSelector(discountCardSelector, (discountCard) => {
    return discountCard.searchResults;
});

export const currentDrugFormSelector = createSelector(discountCardSelector, (discountCard) => {
    return discountCard.currentDrugForm;
});

export const discountCardCurrentDrugSelector = createSelector(discountCardSelector, (discountCard) => {
    return discountCard.currentDrug;
});

export const discountCardDrugFormOptionsSelector = createSelector(discountCardSelector, (discountCard) => {
    return discountCard.formOptions;
});

export const discountCardGeneratedDiscountCardSelector = createSelector(discountCardSelector, (discountCard) => {
    return discountCard.generatedDiscountCard;
});

export const discountCardPharmacyResultsSelector = createSelector(discountCardSelector, (discountCard) => {
    return discountCard.pharmacies;
});

export const discountCardGeneratedSelector = createSelector(discountCardSelector, (discountCard) => {
    return discountCard.generatedCard;
});
