import React from 'react';

import Link from 'ui-kit/link/link';

import LogoDivider from '../logo-divider/logo-divider';
import './co-branding-logo.styles.scss';

interface CoBrandingObjectType {
    nodes: Array<{
        field_co_branding_logo: {
            description: string;
        };
        relationships: {
            field_co_branding_logo: {
                filename: string;
                localFile: {
                    publicURL: string;
                };
            };
        };
    }>;
    linkClassName: string;
}

/**
 * Function to extract and parse URL, alternative text, and title from a CoBranding object.
 * If all `relationships.field_co_branding_logo` fields are `null`, it returns `undefined`.
 *
 * @param {CoBrandingObjectType} obj - The object containing nodes with information about branding logos.
 *
 * @returns {{ url: string; imgAlt: string; imgTitle: string } | undefined} -
 * An object containing the image URL (`url`), alternative text (`imgAlt`), and image title (`imgTitle`).
 * If the logo information is not found or invalid, returns `undefined`.
 */
function parseUrlFromObject(obj: CoBrandingObjectType): { url: string; imgAlt: string; imgTitle: string } | undefined {
    // If all nodes have null in relationships.field_co_branding_logo, return undefined.
    if (obj.nodes?.every((node) => node?.relationships?.field_co_branding_logo === null)) return undefined;

    // Extract the public URL, filename, and description from the first node
    const publicUrl = obj.nodes?.[0]?.relationships?.field_co_branding_logo?.localFile?.publicURL;
    const fileName = obj.nodes?.[0]?.relationships?.field_co_branding_logo?.filename;
    const fileDescription = obj.nodes?.[0]?.field_co_branding_logo?.description;

    // Return the parsed URL, alt text, and title if available.
    return { url: publicUrl, imgAlt: fileName, imgTitle: fileDescription };
}

const CoBrandingLogo = (props: CoBrandingObjectType) => {
    const coBrandingLogoData = parseUrlFromObject(props);
    if (!coBrandingLogoData) return null;

    return (
        <Link
            to="/"
            label={
                <>
                    <div className="co-branding-logo-content">
                        <img
                            src={coBrandingLogoData.url}
                            title={coBrandingLogoData.imgTitle}
                            alt={coBrandingLogoData.imgAlt}
                            loading="lazy"
                        />
                        <LogoDivider />
                    </div>
                </>
            }
            variant="site"
            dataGALocation="CoBrandingLogo"
            ariaLabel="Co-branding environment"
            className={props.linkClassName}
        />
    );
};

export default CoBrandingLogo;
