// Generak
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

// State
import {
    accountAddAddressToProfileRoutine,
    accountAddPaymentRoutine,
    accountAddRepresentativesRoutine,
    accountCancelSmsRequestRoutine,
    accountCheckPendingSmsRequestsRoutine,
    accountCheckSmsRequestsStatusRoutine,
    accountCheckSmsRequestStatusRoutine,
    accountClearMessageThreadRoutine,
    accountClearOrderInvoiceRoutine,
    accountFetchComposeMessageRoutine,
    accountFetchHealthConditionsRoutine,
    accountFetchMessagesRoutine,
    accountFetchNotificationsRoutine,
    accountFetchOrderGetInvoiceRoutine,
    accountFetchOrderHistoryRoutine,
    accountFetchOrderLinesRoutine,
    accountFetchPaymentHistoryRoutine,
    accountFetchPlansRoutine,
    accountFetchProfileRoutine,
    accountFetchReplyMessageRoutine,
    accountFetchSecurityQuestionsRoutine,
    accountFetchWebProfileRoutine,
    accountForgotUsernameRoutine,
    accountGetAcknowledgementRoutine,
    accountGetAllCreditCardsRoutine,
    accountGetAllRepresentativesRoutine,
    accountGetContactUsMessageRoutine,
    accountGetMessageFileDownloadRoutine,
    accountGetMessageFileListRoutine,
    accountGetMessageGetFileDownloadPathRoutine,
    accountGetMessageThreadRoutine,
    accountGetPaymetricDetailsRoutine,
    accountGetUserIpAddressRoutine,
    accountLoginRoutine,
    accountPayBalanceRoutine,
    accountPostCloseMessageRoutine,
    accountPostComposeMessageRoutine,
    accountPostMessageFileUploadRoutine,
    accountPostReplyMessageRoutine,
    accountRegisterRoutine,
    accountRemoveCreditCardRoutine,
    accountRemoveRepresentativesRoutine,
    accountResetPasswordRoutine,
    accountSendChangePasswordRequestRoutine,
    accountSendContactUsMessageRoutine,
    accountSendSmsConfirmationRoutine,
    accountSendSmsConfirmationsRoutine,
    accountSubmitSecurityQuestionAnswerRoutine,
    accountUninsuredRegister,
    accountUpdateAllergiesRoutine,
    accountUpdateAutoRefill,
    accountUpdateCreditCardRoutine,
    accountUpdateMedicalConditionsRoutine,
    accountUpdateProfileRoutine,
    accountUpdateSecurePasswordRoutine,
    accountVerifyPatient
} from 'state/account/account.routines';

// Const
import { isUserUnderAgeLimit } from 'const/options';

// Types
import { Allergies, HealthConditions, MedicalConditions } from 'types/health-profile';
import { LoginRequest } from 'types/login';
import {
    Order,
    OrderGetInvoiceResponsePayload,
    OrderHistoryResponsePayload,
    OrderLinesResponsePayload
} from 'types/order';
import { PaymentHistoryLine, PaymentHistoryResponsePayload } from 'types/payment-history';
import { AssitsOthersFormValues, ProfileUpdateAutoRefill } from 'types/profile';
import { SmsConfirmationSendResponse, SmsGetConfirmationStatusResponse, SMSPendingRequestData } from 'types/sms';

// Utils
import storageHelper from 'util/storageHelper';
import TabBroadcast from 'util/tabBroadcast';

// Services
import {
    AccountSecurityPayload,
    AcknowledgementPayload,
    AllMessagesPayload,
    APILoginPayload,
    ContactUsMessagePayload,
    ContactUsPayload,
    CreditCardPayload,
    HipaaStatusPayload,
    NotificationsPayload,
    PatientRegistrationPayload,
    PaymetricDetailsPayload,
    PlansObjectPayload,
    ProfileObjectPayload,
    ProfileObjectPhonePayload,
    RepresentativesPayload,
    WebProfilePayload
} from './account.services';

export const SESSION_DURATION = 600000;

export const defaultMedicalConditions = {
    Asthma: false,
    COPD: false,
    Cholesterol: false,
    Diabetes: false,
    Glaucoma: false,
    Heart: false,
    Hypertension: false,
    Kidney: false,
    Stroke: false,
    Thyroid: false,
    Other: '',
    TemporaryOther: '',
    ePostPatientNum: ''
};
export const defaultAllergies = {
    Tetracyclines: false,
    Penicillin: false,
    Cephalosporins: false,
    Ampicillin: false,
    Sulfa: false,
    Erythromycin: false,
    Aspirin: false,
    Codeine: false,
    NSAIDs: false,
    Quinolones: false,
    Other: '',
    TemporaryOther: '',
    ePostPatientNum: ''
};

export interface AccountErrorPayload {
    CacheId: string;
    CacheIdMembership: string;
    RetryError: number;
    messageErrorText: string;
    messageStatus: boolean;
    PatientRegistration: Record<string, unknown>; // @TODO: Fix this type
}
export interface AccountState {
    token: string | undefined | null;
    issued: number | undefined;
    expires: number | undefined;
    sessionExpiration?: number;
    registration: {
        hasError: boolean;
        error: string;
        patientRegistration?: PatientRegistrationPayload;
    };
    healthConditions: HealthConditions;
    error?: string;
    isBusy: boolean;
    isLoggingIn: boolean;
    isLoggingOut: boolean;
    isFetchingOrderHistory: boolean;
    isFetchingOrderLines: boolean;
    isFetchingOrderInvoice: boolean;
    isFetchingPaymentHistory: boolean;
    isPayingBalance: boolean;
    isFetchingRepresentatives: boolean;
    isFetchingSecurityQuestions: boolean;
    isSubmittingPasswordReset: boolean;
    isSubmittingSecurityQuestionAnswer: boolean;
    isSubmittingUsernameRequest: boolean;
    isGettingFileList: boolean;
    isUploadingFile: boolean;
    isDownloadingFile: boolean;
    isMinor: boolean;
    isProfileLoading: boolean;
    isLoadingCreditCards: boolean;
    accountSecurity?: AccountSecurityPayload;
    orderHistory?: Order[];
    orderInvoice?: Blob;
    paymentHistory?: PaymentHistoryLine[];
    profileObject?: ProfileObjectPayload;
    plansObject?: PlansObjectPayload[];
    webProfile?: WebProfilePayload;
    passwordChangeSuccess?: boolean | undefined;
    passwordChangeError?: string | undefined;
    creditCards?: CreditCardPayload[];
    representatives?: RepresentativesPayload[];
    paymetricDetails?: PaymetricDetailsPayload | undefined;
    pciToken?: string | undefined;
    contactUs?: ContactUsPayload;
    notifications?: NotificationsPayload;
    messages?: AllMessagesPayload;
    acknowledgement?: AcknowledgementPayload;
    HIPAA?: boolean | undefined;
    currentVid: number | undefined;
    currentIP: string | undefined;
    verifiedPatient: boolean | undefined;
    pendingSmsOptIns: SMSPendingRequestData[];
    showVerifySmsModal: boolean;
    pendingSmsOptInsLoaded: boolean;
    checkingSmsStatus: boolean;
    cancelingSmsRequest: boolean;
    unauthenticatedSesssionExpiration: number;
    userIpAddress: string;
    isAutoRefill: boolean;
    phones?: ProfileObjectPhonePayload[];
    assitsOthersFormValue?: AssitsOthersFormValues;
    signInUsername?: string;
    errorPayload?: AccountErrorPayload | null;
}

export const initialState: AccountState = {
    token: storageHelper.session.getAuthToken(),
    issued: storageHelper.session.getAuthIssued(),
    expires: storageHelper.session.getAuthExpires(),
    sessionExpiration: storageHelper.session.getAuthSessionExpires(),
    registration: {
        hasError: false,
        error: '',
        patientRegistration: undefined
    },
    error: undefined,
    isBusy: false,
    isLoggingIn: false,
    isLoggingOut: false,
    isFetchingOrderHistory: false,
    isFetchingOrderLines: false,
    isFetchingRepresentatives: false,
    isFetchingSecurityQuestions: false,
    isSubmittingPasswordReset: false,
    isSubmittingSecurityQuestionAnswer: false,
    isSubmittingUsernameRequest: false,
    isMinor: false,
    isProfileLoading: false,
    isLoadingCreditCards: false,
    accountSecurity: undefined,
    orderHistory: undefined,
    orderInvoice: undefined,
    profileObject: undefined,
    healthConditions: {
        medicalConditions: defaultMedicalConditions,
        allergies: defaultAllergies
    },
    passwordChangeSuccess: undefined,
    passwordChangeError: undefined,
    creditCards: undefined,
    representatives: undefined,
    notifications: undefined,
    messages: {
        filters: [],
        askThePharm: undefined,
        contactUs: undefined,
        composeMessage: {
            contactUs: undefined,
            askThePharm: undefined
        },
        replyMessage: {
            contactUs: undefined,
            askThePharm: undefined
        },
        messageThread: {
            replies: undefined,
            subject: undefined,
            messageSource: undefined,
            threadStatus: undefined
        }
    },
    currentVid: undefined,
    acknowledgement: {
        TotalCount: undefined,
        PageNo: undefined,
        PageSize: undefined,
        Acknowledgements: [
            {
                EPostPatientNum: '',
                Version: undefined,
                Type: '',
                AddedDate: '',
                Source: '',
                SourceIP: ''
            }
        ]
    },
    pendingSmsOptIns: [],
    showVerifySmsModal: false,
    pendingSmsOptInsLoaded: false,
    checkingSmsStatus: false,
    cancelingSmsRequest: false,
    unauthenticatedSesssionExpiration: Date.now() + SESSION_DURATION,
    userIpAddress: '',
    phones: undefined,
    assitsOthersFormValue: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        ePostPatientNumber: '',
        dobMonth: '',
        dobDay: '',
        dobYear: '',
        prescriptionNumber: '',
        gender: '',
        race: '',
        ethnicity: '',
        email: '',
        address1: '',
        city: '',
        state: '',
        zipcode: '',
        password: '',
        passwordConfirm: ''
    },
    errorPayload: null
};

export const resetReducersOnLogout = createAction('ACCOUNT/LOGOUT');

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        logout() {
            storageHelper.session.removeAuthToken();
            storageHelper.session.removePrescriptionFlowType();
            storageHelper.session.removePrescribers();
            storageHelper.session.removePharmacies();
            if (typeof window !== undefined) {
                if (window.dataLayer) {
                    window.dataLayer.push({ event: 'logout', loginStatus: 'logged out' });
                }
            }
            // Inform the rest of the tabs that the session needs to be closed
            TabBroadcast.getInstance().sendMessage('logout');
            return {
                ...initialState,
                isLoggingOut: true,
                token: null,
                issued: undefined,
                expires: undefined,
                sessionExpiration: undefined
            };
        },
        refreshSessionExpiration(state: AccountState) {
            state.sessionExpiration = Date.now() + SESSION_DURATION;
            storageHelper.session.setAuthSessionExpires(state.sessionExpiration.toString());
        },
        refreshUnauthenticatedSession(state) {
            state.unauthenticatedSesssionExpiration = Date.now() + SESSION_DURATION;
        },
        setCurrentVid(state: any, action) {
            state.currentVid = action.payload;
        },
        resetSmsVerification: (state) => {
            state.pendingSmsOptIns = initialState.pendingSmsOptIns;
            state.showVerifySmsModal = initialState.showVerifySmsModal;
            state.pendingSmsOptInsLoaded = initialState.pendingSmsOptInsLoaded;
            state.checkingSmsStatus = initialState.checkingSmsStatus;
        },
        clearPasswordResetForm: (state: any) => {
            state.accountSecurity = initialState.accountSecurity;
        },
        clearAssistOthersFormValues: (state: any) => {
            state.assitsOthersFormValue = initialState.assitsOthersFormValue;
            state.isBusy = false;
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Account Login Reducers
         */
        addCase(accountFetchHealthConditionsRoutine.SUCCESS, (state, { payload }: PayloadAction<HealthConditions>) =>
            produce(state, (draftState) => {
                draftState.healthConditions = {
                    medicalConditions: {
                        ...payload.medicalConditions,
                        TemporaryOther: state.healthConditions.medicalConditions.TemporaryOther
                    },
                    allergies: {
                        ...payload.allergies,
                        TemporaryOther: state.healthConditions.allergies.TemporaryOther
                    }
                };
            })
        );
        addCase(accountLoginRoutine.TRIGGER, (state, { payload }: PayloadAction<LoginRequest>) =>
            produce(state, (draftState) => {
                draftState.isLoggingIn = true;
                draftState.signInUsername = payload.login.email;
            })
        );
        addCase(accountLoginRoutine.SUCCESS, (state, { payload }: PayloadAction<APILoginPayload>) =>
            produce(state, (draftState) => {
                draftState.token = payload.access_token;
                draftState.expires = Date.parse(payload['.expires']);
                draftState.issued = Date.parse(payload['.issued']);
                draftState.isLoggingIn = false;
                draftState.isLoggingOut = false;
                draftState.sessionExpiration = Date.now() + SESSION_DURATION;

                storageHelper.session.setAuthToken(
                    payload.access_token,
                    draftState.issued.toString(),
                    draftState.expires.toString(),
                    draftState.sessionExpiration.toString()
                );
            })
        );
        addCase(accountLoginRoutine.FAILURE, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.error = payload;
                draftState.isLoggingIn = false;
                draftState.isLoggingOut = true;
            })
        );

        /**
         * Order History
         */
        addCase(accountFetchOrderHistoryRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingOrderHistory = true;
            })
        );
        addCase(
            accountFetchOrderHistoryRoutine.SUCCESS,
            (state, { payload }: PayloadAction<OrderHistoryResponsePayload>) =>
                produce(state, (draftState) => {
                    draftState.isFetchingOrderHistory = false;
                    draftState.orderHistory = payload.orders;
                })
        );
        addCase(accountFetchOrderHistoryRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingOrderHistory = false;
            })
        );

        /**
         * Order Lines
         */
        addCase(accountFetchOrderLinesRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingOrderLines = true;
            })
        );
        addCase(accountFetchOrderLinesRoutine.SUCCESS, (state, { payload }: PayloadAction<OrderLinesResponsePayload>) =>
            produce(state, (draftState) => {
                if (draftState.orderHistory) {
                    draftState.isFetchingOrderLines = false;
                    const order = draftState.orderHistory.find(
                        (order) => order.epostOrderNum === payload.epostOrderNum
                    );
                    if (order) {
                        order.orderLines = payload.orderLines;
                    }
                }
            })
        );
        addCase(accountFetchOrderLinesRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingOrderLines = false;
            })
        );

        /**
         * Order GetInvoice
         */
        addCase(accountFetchOrderGetInvoiceRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingOrderInvoice = true;
            })
        );
        addCase(
            accountFetchOrderGetInvoiceRoutine.SUCCESS,
            (state, { payload }: PayloadAction<OrderGetInvoiceResponsePayload>) =>
                produce(state, (draftState) => {
                    draftState.isFetchingOrderInvoice = false;
                    draftState.orderInvoice = payload.data;
                })
        );
        addCase(accountFetchOrderGetInvoiceRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingOrderInvoice = false;
                draftState.orderInvoice = undefined;
            })
        );
        addCase(accountClearOrderInvoiceRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingOrderInvoice = true;
            })
        );
        addCase(accountClearOrderInvoiceRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingOrderInvoice = false;
                draftState.orderInvoice = undefined;
            })
        );
        /**
         * Payment History
         */
        addCase(accountFetchPaymentHistoryRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingPaymentHistory = true;
            })
        );
        addCase(
            accountFetchPaymentHistoryRoutine.SUCCESS,
            (state, { payload }: PayloadAction<PaymentHistoryResponsePayload>) =>
                produce(state, (draftState) => {
                    draftState.isFetchingPaymentHistory = false;
                    draftState.paymentHistory = payload.payments;
                })
        );
        addCase(accountFetchPaymentHistoryRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingPaymentHistory = false;
            })
        );
        /**
         * Pay Balance
         */
        addCase(accountPayBalanceRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isPayingBalance = true;
            })
        );
        addCase(accountPayBalanceRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isPayingBalance = false;
            })
        );
        addCase(accountPayBalanceRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isPayingBalance = false;
            })
        );
        /**
         * Profile Fetching Reducers
         */
        addCase(accountFetchProfileRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isProfileLoading = true;
            })
        );
        addCase(accountFetchProfileRoutine.SUCCESS, (state, { payload }: PayloadAction<ProfileObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.profileObject = payload;
                draftState.isMinor = isUserUnderAgeLimit(payload?.patientDob);
                draftState.isProfileLoading = false;
            })
        );
        addCase(accountFetchProfileRoutine.FAILURE, (state, { payload }: PayloadAction<ProfileObjectPayload>) =>
            produce(state, (draftState) => {
                // Keep the profile object even when there is an API error,
                // so we avoid unnecessary loading and keep the profile object filled.
                draftState.profileObject = { ...draftState.profileObject, ...payload };
                draftState.isProfileLoading = false;
            })
        );
        /**
         * Plans Fetching Reducers
         */
        addCase(accountFetchPlansRoutine.SUCCESS, (state, { payload }: PayloadAction<PlansObjectPayload[]>) =>
            produce(state, (draftState) => {
                draftState.plansObject = payload;
            })
        );
        addCase(accountFetchPlansRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.plansObject = undefined;
            })
        );
        /**
         * Health Conditions
         */
        addCase(accountUpdateMedicalConditionsRoutine.SUCCESS, (state, { payload }: PayloadAction<MedicalConditions>) =>
            produce(state, (draftState) => {
                if (draftState.healthConditions) {
                    draftState.healthConditions.medicalConditions.TemporaryOther = payload.TemporaryOther;
                } else {
                    draftState.healthConditions = {
                        allergies: state.healthConditions?.allergies || defaultAllergies,
                        medicalConditions: {
                            ...payload,
                            TemporaryOther: state.healthConditions?.medicalConditions.TemporaryOther
                        }
                    };
                }
            })
        );
        addCase(accountUpdateAllergiesRoutine.SUCCESS, (state, { payload }: PayloadAction<Allergies>) =>
            produce(state, (draftState) => {
                if (draftState.healthConditions) {
                    draftState.healthConditions.allergies.TemporaryOther = payload.TemporaryOther;
                } else {
                    draftState.healthConditions = {
                        allergies: {
                            ...payload,
                            TemporaryOther: state.healthConditions?.allergies.TemporaryOther
                        },
                        medicalConditions: state.healthConditions?.medicalConditions || defaultMedicalConditions
                    };
                }
            })
        );

        /**
         * Account Register Reducers
         */
        addCase(accountRegisterRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.registration.hasError = false;
                draftState.registration.error = '';
            })
        );
        addCase(
            accountRegisterRoutine.SUCCESS,
            (
                state,
                {
                    payload: { patientRegistrationPayload, formValues }
                }: PayloadAction<{
                    patientRegistrationPayload: PatientRegistrationPayload;
                    formValues: any;
                }>
            ) =>
                produce(state, (draftState) => {
                    draftState.registration.hasError = false;
                    draftState.registration.error = '';

                    const regProfile = patientRegistrationPayload;
                    if (formValues.insuranceChoice === 'discount') {
                        regProfile.AddressSeqNumb = -1; //HOME
                        regProfile.Address1 = formValues.address1;
                        regProfile.Address2 = formValues.address2;
                        regProfile.City = formValues.city;
                        regProfile.StateCode = formValues.state;
                        regProfile.Country = 'USA';
                        regProfile.ZipCode =
                            formValues.zipcode.length > 5 ? formValues.zipcode.slice(0, 5) : formValues.zipcode;
                        regProfile.Zip4 = formValues.zipcode.length > 5 ? formValues.zipcode.slice(-4) : '';
                    }
                    draftState.registration.patientRegistration = regProfile;
                })
        );
        addCase(accountRegisterRoutine.FAILURE, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.registration.hasError = true;
                draftState.registration.error = payload;
            })
        );

        /**
         * Account Update Profile Reducers
         */
        addCase(accountUpdateProfileRoutine.SUCCESS, (state, { payload }: PayloadAction<ProfileObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.profileObject = payload;
                draftState.isMinor = isUserUnderAgeLimit(payload?.patientDob);
            })
        );

        addCase(accountUpdateProfileRoutine.FAILURE, (state, { payload }: PayloadAction<ProfileObjectPayload>) =>
            produce(state, (draftState) => {
                // TODO: Investigate payload
                draftState.profileObject = payload;
            })
        );

        /**
         * Account Add Address Reducers
         */
        addCase(accountAddAddressToProfileRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(accountAddAddressToProfileRoutine.SUCCESS, (state, { payload }: PayloadAction<ProfileObjectPayload>) =>
            produce(state, (draftState) => {
                draftState.profileObject = payload;
            })
        );

        /**
         * Web Profile Fetching Reducers
         */
        addCase(accountFetchWebProfileRoutine.SUCCESS, (state, { payload }: PayloadAction<WebProfilePayload>) =>
            produce(state, (draftState) => {
                draftState.webProfile = payload;
            })
        );
        addCase(accountFetchWebProfileRoutine.FAILURE, (state, { payload }: PayloadAction<WebProfilePayload>) =>
            produce(state, (draftState) => {
                draftState.webProfile = undefined;
            })
        );

        /**
         * Secure Password Updating Reducers
         */
        addCase(accountUpdateSecurePasswordRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.passwordChangeSuccess = payload;
            })
        );
        addCase(accountUpdateSecurePasswordRoutine.FAILURE, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.passwordChangeError = payload;
            })
        );

        /**
         * Password Reset
         */
        addCase(accountFetchSecurityQuestionsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingSecurityQuestions = true;
            })
        );
        addCase(
            accountFetchSecurityQuestionsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AccountSecurityPayload>) =>
                produce(state, (draftState) => {
                    draftState.isFetchingSecurityQuestions = false;
                    draftState.accountSecurity = payload;
                })
        );
        addCase(accountFetchSecurityQuestionsRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingSecurityQuestions = false;
                draftState.accountSecurity = undefined;
            })
        );
        addCase(accountSubmitSecurityQuestionAnswerRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingSecurityQuestionAnswer = true;
            })
        );
        addCase(accountSubmitSecurityQuestionAnswerRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingSecurityQuestionAnswer = false;
            })
        );
        addCase(accountSubmitSecurityQuestionAnswerRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingSecurityQuestionAnswer = false;
            })
        );
        addCase(accountResetPasswordRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingPasswordReset = true;
            })
        );
        addCase(accountResetPasswordRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingPasswordReset = false;
            })
        );
        addCase(accountResetPasswordRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingPasswordReset = false;
            })
        );
        addCase(accountSendChangePasswordRequestRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingPasswordReset = true;
            })
        );
        addCase(accountSendChangePasswordRequestRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingPasswordReset = false;
            })
        );
        addCase(accountSendChangePasswordRequestRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingPasswordReset = false;
            })
        );

        /**
         * Forgot username
         */
        addCase(accountForgotUsernameRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingUsernameRequest = true;
            })
        );
        addCase(accountForgotUsernameRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingUsernameRequest = false;
            })
        );
        addCase(accountForgotUsernameRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isSubmittingUsernameRequest = false;
            })
        );

        /**
         * Account Representatives Reducers
         */
        addCase(accountGetAllRepresentativesRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isFetchingRepresentatives = true;
            })
        );
        addCase(
            accountGetAllRepresentativesRoutine.SUCCESS,
            (state, { payload }: PayloadAction<RepresentativesPayload[]>) =>
                produce(state, (draftState) => {
                    draftState.representatives = payload;
                    draftState.isFetchingRepresentatives = false;
                })
        );

        addCase(accountGetAllRepresentativesRoutine.FAILURE, (state, { payload }: PayloadAction<[]>) =>
            produce(state, (draftState) => {
                draftState.representatives = payload;
                draftState.isFetchingRepresentatives = false;
            })
        );

        addCase(
            accountAddRepresentativesRoutine.SUCCESS,
            (state, { payload }: PayloadAction<RepresentativesPayload[]>) =>
                produce(state, (draftState) => {
                    // comment out when check error message, this API shares same path and part of response data object with GET.
                    // draftState.representatives = payload;
                })
        );

        addCase(
            accountRemoveRepresentativesRoutine.SUCCESS,
            (state, { payload }: PayloadAction<RepresentativesPayload[] | undefined>) =>
                produce(state, (draftState) => {
                    // comment out when check error message, this API shares same path and part of response data object with GET.
                    // draftState.representatives = payload;
                })
        );

        /**
         * Account Credit Cards Reducers
         */

        addCase(accountGetAllCreditCardsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingCreditCards = true;
            })
        );
        addCase(accountGetAllCreditCardsRoutine.SUCCESS, (state, { payload }: PayloadAction<CreditCardPayload[]>) =>
            produce(state, (draftState) => {
                draftState.creditCards = payload;
                draftState.isLoadingCreditCards = false;
            })
        );
        addCase(accountGetAllCreditCardsRoutine.FAILURE, (state, { payload }: PayloadAction<[]>) =>
            produce(state, (draftState) => {
                draftState.creditCards = payload;
                draftState.isLoadingCreditCards = false;
            })
        );

        /**
         * Account Add Payment Method
         */
        addCase(accountAddPaymentRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingCreditCards = true;
            })
        );
        addCase(accountAddPaymentRoutine.SUCCESS, (state, { payload }: PayloadAction<CreditCardPayload[]>) =>
            produce(state, (draftState) => {
                draftState.creditCards = payload;
                draftState.isLoadingCreditCards = false;
            })
        );
        addCase(accountAddPaymentRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingCreditCards = false;
            })
        );

        /**
         * Account Remove Payment Method
         */
        addCase(accountRemoveCreditCardRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingCreditCards = true;
            })
        );
        addCase(
            accountRemoveCreditCardRoutine.SUCCESS,
            (state, { payload }: PayloadAction<CreditCardPayload[] | undefined>) =>
                produce(state, (draftState) => {
                    draftState.creditCards = payload;
                    draftState.isLoadingCreditCards = false;
                })
        );
        addCase(accountRemoveCreditCardRoutine.FAILURE, (state, { payload }: PayloadAction<[]>) =>
            produce(state, (draftState) => {
                draftState.creditCards = payload;
                draftState.isLoadingCreditCards = false;
            })
        );

        /**
         * Account Remove Payment Method
         */
        addCase(accountUpdateCreditCardRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingCreditCards = true;
            })
        );
        addCase(accountUpdateCreditCardRoutine.SUCCESS, (state, { payload }: PayloadAction<CreditCardPayload[]>) =>
            produce(state, (draftState) => {
                draftState.creditCards = payload;
                draftState.isLoadingCreditCards = false;
            })
        );

        addCase(accountUpdateCreditCardRoutine.FAILURE, (state, { payload }: PayloadAction<[]>) =>
            produce(state, (draftState) => {
                draftState.creditCards = payload;
                draftState.isLoadingCreditCards = false;
            })
        );

        addCase(accountGetPaymetricDetailsRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.paymetricDetails = payload;
            })
        );

        addCase(accountGetContactUsMessageRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.contactUs = payload;
            })
        );

        addCase(accountSendContactUsMessageRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.contactUs = payload;
            })
        );

        /**
         * Account Notifications Reducers
         */
        addCase(accountFetchNotificationsRoutine.SUCCESS, (state, { payload }: PayloadAction<NotificationsPayload>) =>
            produce(state, (draftState) => {
                draftState.notifications = payload;
            })
        );

        addCase(accountFetchNotificationsRoutine.FAILURE, (state, { payload }: PayloadAction<NotificationsPayload>) =>
            produce(state, (draftState) => {
                draftState.notifications = payload;
            })
        );

        addCase(accountGetMessageFileListRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isGettingFileList = true;
            })
        );
        addCase(accountGetMessageFileListRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isGettingFileList = false;
            })
        );
        addCase(accountGetMessageFileListRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isGettingFileList = false;
            })
        );

        addCase(accountPostMessageFileUploadRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isUploadingFile = true;
            })
        );
        addCase(accountPostMessageFileUploadRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isUploadingFile = false;
            })
        );
        addCase(accountPostMessageFileUploadRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isUploadingFile = false;
            })
        );

        addCase(accountGetMessageFileDownloadRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isDownloadingFile = true;
            })
        );
        addCase(accountGetMessageFileDownloadRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isDownloadingFile = false;
            })
        );
        addCase(accountGetMessageFileDownloadRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isDownloadingFile = false;
            })
        );

        addCase(accountGetMessageGetFileDownloadPathRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isDownloadingFile = true;
            })
        );
        addCase(accountGetMessageGetFileDownloadPathRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isDownloadingFile = false;
            })
        );
        addCase(accountGetMessageGetFileDownloadPathRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isDownloadingFile = false;
            })
        );

        /**
         * Account Messages Reducers
         */

        addCase(accountFetchMessagesRoutine.SUCCESS, (state, { payload }: PayloadAction<ContactUsMessagePayload[]>) =>
            produce(state, (draftState) => {
                draftState.messages.askThePharm = payload.askThePharm;
                draftState.messages.contactUs = payload.contactUs;
            })
        );

        addCase(accountFetchMessagesRoutine.FAILURE, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.messages.askThePharm = payload.askThePharm;
                draftState.messages.contactUs = payload.contactUs;
            })
        );

        addCase(
            accountFetchComposeMessageRoutine.SUCCESS,
            (state, { payload }: PayloadAction<ContactUsMessagePayload[]>) =>
                produce(state, (draftState) => {
                    draftState.messages.composeMessage.askThePharm = payload.askThePharm;
                    draftState.messages.composeMessage.contactUs = payload.contactUs;
                })
        );

        addCase(
            accountPostComposeMessageRoutine.SUCCESS,
            (state, { payload }: PayloadAction<ContactUsMessagePayload[]>) =>
                produce(state, (draftState) => {
                    if (payload.messageType === 'Ask The Pharmacist') {
                        draftState.messages.composeMessage.askThePharm = payload;
                    } else {
                        draftState.messages.composeMessage.contactUs = payload;
                    }
                })
        );

        addCase(
            accountPostCloseMessageRoutine.SUCCESS,
            (state, { payload }: PayloadAction<ContactUsMessagePayload[]>) =>
                produce(state, (draftState) => {
                    if (payload.messageType === 'Ask The Pharmacist') {
                        draftState.messages.composeMessage.askThePharm = payload;
                    } else {
                        draftState.messages.composeMessage.contactUs = payload;
                    }
                })
        );

        addCase(accountGetMessageThreadRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.messages.messageThread = payload;
            })
        );

        addCase(accountClearMessageThreadRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.messages.messageThread = payload;
            })
        );

        addCase(
            accountFetchReplyMessageRoutine.SUCCESS,
            (state, { payload }: PayloadAction<ContactUsMessagePayload[]>) =>
                produce(state, (draftState) => {
                    if (payload.messageType === 'Ask The Pharmacist') {
                        draftState.messages.replyMessage.askThePharm = payload;
                    } else {
                        draftState.messages.replyMessage.contactUs = payload;
                    }
                })
        );

        addCase(
            accountPostReplyMessageRoutine.SUCCESS,
            (state, { payload }: PayloadAction<ContactUsMessagePayload[]>) =>
                produce(state, (draftState) => {
                    if (payload.messageType === 'Ask The Pharmacist') {
                        draftState.messages.replyMessage.askThePharm = payload;
                    } else {
                        draftState.messages.replyMessage.contactUs = payload;
                    }
                })
        );

        /**
         * Account acknowledgement Reducers
         */
        addCase(
            accountGetAcknowledgementRoutine.SUCCESS,
            (state, { payload }: PayloadAction<[AcknowledgementPayload, HipaaStatusPayload]>) =>
                produce(state, (draftState) => {
                    // This routine fetches both acknowledgement and HIPAA as an array
                    if (payload && payload.length === 2) {
                        draftState.acknowledgement = payload[0];
                        draftState.HIPAA = payload[1].HIPAAA; // Yes there is an extra "A" in the payload parameter name
                    } else {
                        draftState.acknowledgement = undefined;
                        draftState.HIPAA = undefined;
                    }
                })
        );
        addCase(accountGetAcknowledgementRoutine.FAILURE, (state, { payload }: PayloadAction<AcknowledgementPayload>) =>
            produce(state, (draftState) => {
                draftState.acknowledgement = undefined;
                draftState.HIPAA = undefined;
            })
        );

        addCase(accountVerifyPatient.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.verifiedPatient = payload;
            })
        );

        addCase(accountVerifyPatient.FAILURE, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.verifiedPatient = payload;
            })
        );
        // SMS Double Opt In callbacks
        // Send SMS Confirmation (Opt In) Text
        addCase(
            accountSendSmsConfirmationRoutine.SUCCESS,
            (state, { payload }: PayloadAction<SmsConfirmationSendResponse>) =>
                produce(state, (draftState) => {
                    /* Do nothing */
                })
        );
        addCase(accountSendSmsConfirmationRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                /* Do nothing */
            })
        );
        // Check for any pending requests
        addCase(
            accountCheckPendingSmsRequestsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<SMSPendingRequestData[]>) =>
                produce(state, (draftState) => {
                    draftState.pendingSmsOptInsLoaded = true;
                    draftState.pendingSmsOptIns = payload;
                })
        );
        addCase(accountCheckPendingSmsRequestsRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.pendingSmsOptInsLoaded = true;
                draftState.pendingSmsOptIns = initialState.pendingSmsOptIns;
            })
        );
        // Check status for specific request
        addCase(
            accountCheckSmsRequestStatusRoutine.SUCCESS,
            (state, { payload }: PayloadAction<SmsGetConfirmationStatusResponse>) =>
                produce(state, (draftState) => {
                    const pendingOptins = state.pendingSmsOptIns.map((optin) => {
                        if (optin.SecurityToken === payload.securityToken) {
                            return { ...optin, status: payload.ConfirmationStatus };
                        }
                        return optin;
                    });

                    draftState.pendingSmsOptIns = pendingOptins;
                    draftState.checkingSmsStatus = initialState.checkingSmsStatus;
                })
        );
        addCase(accountCheckSmsRequestStatusRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                /* Do nothing */
            })
        );
        // Cancel all pending SMS opt in requets
        addCase(accountCancelSmsRequestRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.cancelingSmsRequest = true;
            })
        );
        addCase(accountCancelSmsRequestRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.pendingSmsOptIns = initialState.pendingSmsOptIns;
                draftState.pendingSmsOptInsLoaded = initialState.pendingSmsOptInsLoaded;
                draftState.checkingSmsStatus = initialState.checkingSmsStatus;
                draftState.cancelingSmsRequest = initialState.cancelingSmsRequest;
            })
        );
        addCase(accountCancelSmsRequestRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.pendingSmsOptIns = initialState.pendingSmsOptIns;
                draftState.pendingSmsOptInsLoaded = initialState.pendingSmsOptInsLoaded;
                draftState.checkingSmsStatus = initialState.checkingSmsStatus;
                draftState.cancelingSmsRequest = initialState.cancelingSmsRequest;
            })
        );
        addCase(
            accountSendSmsConfirmationsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<{ showVerifySmsModal: boolean }>) =>
                produce(state, (draftState) => {
                    draftState.showVerifySmsModal = payload.showVerifySmsModal;
                })
        );
        addCase(accountCheckSmsRequestsStatusRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.checkingSmsStatus = true;
            })
        );
        addCase(accountCheckSmsRequestsStatusRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.checkingSmsStatus = false;
            })
        );
        addCase(accountCheckSmsRequestsStatusRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.checkingSmsStatus = false;
            })
        );
        addCase(accountGetUserIpAddressRoutine.SUCCESS, (state, { payload }: PayloadAction<{ ip: string }>) =>
            produce(state, (draftState) => {
                draftState.userIpAddress = payload.ip;
            })
        );

        addCase(accountUpdateAutoRefill.TRIGGER, (state, { payload }: PayloadAction<ProfileUpdateAutoRefill>) =>
            produce(state, (draftState) => {
                draftState.isAutoRefill = payload.isAutoRefill;
            })
        );

        addCase(accountUpdateAutoRefill.SUCCESS, (state, { payload }: PayloadAction<ProfileUpdateAutoRefill>) =>
            produce(state, (draftState) => {
                draftState.isAutoRefill = payload.isAutoRefill;
            })
        );

        addCase(accountUpdateAutoRefill.FAILURE, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.error = payload;
            })
        );

        // MARK: Assist others
        addCase(accountUninsuredRegister.TRIGGER, (state, { payload }: PayloadAction<any>) => {
            return produce(state, (draftState) => {
                draftState.isBusy = true;
                draftState.assitsOthersFormValue = payload.formData;
            });
        });
        addCase(accountUninsuredRegister.SUCCESS, (state) => {
            return produce(state, (draftState) => {
                draftState.errorPayload = null;
                draftState.isBusy = false;
            });
        });
        addCase(accountUninsuredRegister.FAILURE, (state, { payload }: PayloadAction<AccountErrorPayload>) => {
            return produce(state, (draftState) => {
                draftState.errorPayload = payload;
                draftState.isBusy = false;
            });
        });
    }
});

export const {
    logout,
    refreshSessionExpiration,
    refreshUnauthenticatedSession,
    setCurrentVid,
    resetSmsVerification,
    clearPasswordResetForm,
    clearAssistOthersFormValues
} = accountSlice.actions;

export default accountSlice.reducer;
