import React from 'react';

const LogoDivider = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="48" viewBox="0 0 20 48">
            <g>
                <path
                    fill="none"
                    stroke="rgb(172,185,200)"
                    strokeLinecap="square"
                    strokeLinejoin="miter"
                    strokeWidth="1"
                    d="M10 2.35231505v50"
                ></path>
            </g>
        </svg>
    );
};

export default LogoDivider;
