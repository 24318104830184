import { GATSBY_API_BASE_URL, SCRIPT_SAVE_API_URL } from 'gatsby-env-variables';

import {
    DiscountCardResponse,
    DrugSearchResponse,
    searchDrugsByNameParams,
    SendSmsEmailCardPayload
} from 'types/discount-card';

import axiosClient from 'util/axiosClient';
import { buildQueryParams } from 'util/object';

export default class DiscountCardService {
    static getAutocompleteValues() {
        return {
            get: async (payload: any): Promise<any> => {
                const response = await axiosClient.get(
                    `${SCRIPT_SAVE_API_URL}/PricingAPI/api/PricingEngineExternal/AutoComplete?PrefixText=${payload.search}&Count=5`
                );
                return response.data;
            }
        };
    }

    static searchDrugByNameAndZip() {
        return {
            get: async (
                payload: Pick<searchDrugsByNameParams, 'drugName' | 'zipCode'>
            ): Promise<DrugSearchResponse> => {
                const queryParams = buildQueryParams(payload);
                const response = await axiosClient.get(
                    `${SCRIPT_SAVE_API_URL}/PricingAPI/api/PricingEngineExternal/FindDrugs?${queryParams}`
                );
                return response.data;
            }
        };
    }

    static searchDrugByEditFilters() {
        return {
            get: async (
                payload: Pick<searchDrugsByNameParams, 'brandIndicator' | 'referencedBN' | 'qty' | 'GSN'>
            ): Promise<DrugSearchResponse> => {
                const queryParams = buildQueryParams(payload);
                const response = await axiosClient.get(
                    `${SCRIPT_SAVE_API_URL}/PricingAPI/api/PricingEngineExternal/FindDrugs?${queryParams}`
                );
                return response.data;
            }
        };
    }

    static generateDiscountCard() {
        return {
            get: async (): Promise<DiscountCardResponse> => {
                const response = await axiosClient.get(
                    `${SCRIPT_SAVE_API_URL}/pricingenginecore/api/pricing/GenerateCardHolder?groupID=4530`
                );
                return response.data;
            }
        };
    }
    static sendCard() {
        return {
            post: async (payload: SendSmsEmailCardPayload): Promise<any> => {
                const response = await axiosClient.post(
                    `${GATSBY_API_BASE_URL}/api/Notification/DiscountCardNotification`,
                    payload
                );
                return response.data;
            }
        };
    }
    static searchDrugFormStrength() {
        return {
            get: async (payload: Pick<searchDrugsByNameParams, 'GSN'>): Promise<DrugSearchResponse> => {
                const queryParams = buildQueryParams(payload);
                const response = await axiosClient.get(
                    `${SCRIPT_SAVE_API_URL}/PricingAPI/api/PricingEngineExternal/DrugFormStrength?${queryParams}`
                );
                return response.data;
            }
        };
    }
}
