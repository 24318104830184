export function formatPrice(priceAmount: any | undefined): string {
    return parseFloat(priceAmount ? priceAmount.toString().replace(/[^\d\.]/g, '') : 0).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
    });
}

export function formatPercent(partialValue: any | undefined, totalValue: any | undefined): string {
    const partialValueNum = partialValue ? Number(partialValue) : 0;
    const totalValueNum = totalValue ? Number(totalValue) : 0;
    const percentValue = (100 * partialValueNum) / totalValueNum;
    return `${Math.round(percentValue * 100) / 100}`;
}

export function formatCurrency(value: number) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    });
    return formatter.format(value);
}
